<template>
    <v-form
        @keyup.native.enter="enterKeySubmit"
    >
        <v-row align="center" justify="center">
            <v-col cols="10" sm="4" md="4" lg="2" align="center">
                <v-text-field
                    v-model="email"
                    label="Email"
                    required
                />
                <v-text-field
                    v-model="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    label="Password"
                    hint="Max 18 characters"
                    value=""
                    class="input-group--focused"
                    @click:append="show = !show"
                />
            </v-col>
        </v-row>
        <v-row align="center" justify="center">
            <v-btn
                class="white--text"
                color="#3949AB"
                :disabled="shouldDisableSubmit()"
                :loading="dialog"
                @click="submit"
            >
                submit
            </v-btn>
        </v-row>
    </v-form>
</template>

<script>
    import store from '@/store'
    import userService from '@/services/user'

    export default {
        props: {
        },
        data() {
            return {
                show: false,
                dialog: false,
                email: '',
                password: '',
                message: ''
            }
        },
        methods: {
            enterKeySubmit() {
                if (!this.shouldDisableSubmit()) {
                    this.submit()
                }
            },
            shouldDisableSubmit() {
                if (!this.password || !this.email) {
                    return true
                }
                return false
            },
            submit() {
                this.dialog = true
                userService.login({
                    username: this.email,
                    password: this.password
                }).then(resp => {
                    this.dialog = false
                    store.dispatch('login', [resp]).then(() => this.$router.push('/'))
                }).catch(error => {
                    this.error = error.response.data
                    this.message = error.response.data.message
                    this.$emit('setAlert', this.message, 'error')
                    this.dialog = false
                })
            }
        }
    }
</script>

<style scoped>
</style>
