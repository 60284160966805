<template>
    <v-container
        class="mx-auto container"
        justify="center"
    >
        <v-row>
            <v-col cols="12" align="center">
                <div class="image-wrapper pb-1">
                    <v-img height="auto" width="150px" :src="require('@/assets/main-logo.png')" />
                </div>
            </v-col>
        </v-row>
        <v-row class="my-0 py-0">
            <v-col cols="12" class="px-16 login-form" align="center">
                <login-form @setAlert="setAlert" />
            </v-col>
        </v-row>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import LoginForm from '@/forms/LoginForm'
    import Alert from '@/components/Alert'

    export default {
        components: { LoginForm, Alert },
        data() {
            return {
                showAlert: false,
                message: '',
                type: null
            }
        },
        watch: {
            tab: {
                handler(newval, oldval) {
                    if (newval) {
                        if (this.showAlert !== false) {
                            this.showAlert = false
                        }
                    }
                }
            },
            showAlert: {
                handler(newval, oldval) {
                    if (newval !== oldval) {
                        setTimeout(() => { this.showAlert = false }, 7000)
                    }
                }
            }
        },
        created() {
        },
        methods: {
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
            }
        }
    }
</script>

<style scoped>

.login-form{
    width: 300px;
}
.error-message {
    display: flex;
    justify-content: center;
    color: red;
    font-weight: bold;
}
.image-wrapper{
    width: 25%;
    margin: 0 auto;
}

.container {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding-bottom: 10vh;
}

</style>
